import React from 'react';
import '../assets/css/agency.min.css';

const NotFoundPage = () => {
  return( 
  <section className="section-container">
	<div className="form-container">
		<h1 style={{textAlign:"center"}}>404 Page Not Found</h1>
    </div>
    </section>
  
  );
}

export default NotFoundPage;