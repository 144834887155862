import React from 'react';
import FormChange from './form_change';

function Signup() {
  return (
    <>      
    <FormChange value />
    </>
  );
} export default Signup;

